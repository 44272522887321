import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import Headline from '../headline/headline'
import HTMLWrapper from '../htmlWrapper/htmlWrapper'
import Media from '../media/media'
import RichText from '../richtext/richtext'

import {
  getCellClass,
  getBackgroundClass,
  getMediaType,
  isMobile
} from '../utils'

import './_section.scss'

const Section = ({
  children,
  section,
  id,
  max
}) => {
  let backgroundImage = null
  let isBackgroundVideo = false
  let theme = 'lite'
  let fragments = []
  let cellAlignment = 'center'
  let sectionClass = 'section'
  const isStage = id === 0 && max > 1
  const useArrow = isStage && max > 1
  let secSection = '#'
  let loadingClass = 'lazy'

  if (section) {
    if (section.backgroundImage) {
      backgroundImage = section.backgroundImage
      isBackgroundVideo = getMediaType(backgroundImage.file.url) === 'mp4'
    }

    if (isStage) {
      sectionClass = 'section section--stage'
      loadingClass = 'eager'
    }

    switch(section.alignment.toLowerCase()){
      case 'left':
        cellAlignment = 'align-left'
        break
      case 'center':
        cellAlignment = 'align-center'
        break
      case 'right':
        cellAlignment = 'align-right'
        break
      default:
        break
    }

    theme = section.theme.toLowerCase()
    fragments = section.fragments

    if (useArrow) {
      secSection = '#jumpID'
      gsap.registerPlugin(ScrollToPlugin)
    }
  }

  const getJumpUrl = (event) => {
    const jumpId = document.querySelectorAll('section.section')[1].id

    event.currentTarget.href = '#' + jumpId
  }

  const handleJump = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const targetID = e.currentTarget.getAttribute('href')
    const target = document.getElementById(targetID.replace('#', ''))
    const offSet = isMobile() ? 0 : -90

    gsap.to(window, { duration: .250, scrollTo: { y: target, offsetY: offSet }, ease: "none" })
  }

  if (section.gridGap) {
    switch (section.gridGap){
      case 'Regular':
        sectionClass += ''
        break
      case 'Wide':
        sectionClass += ' section--wide'
        break
      case 'Wider':
        sectionClass += ' section--wider'
        break
      default:
        console.log('Grid gap not assigned yet')
        break
    }
  }

  return (
    <section className={sectionClass} id={section.id}>
      <div className={`section__wrapper section__wrapper--${theme}`}>
        <div className="grid-container">
          <div className={`grid-x grid-padding-x ${cellAlignment}`}>
            { children }

            { fragments.map((fragment) => {
              switch (fragment.__typename){
                case 'ContentfulFragmentHeadline':
                  return (
                    <div key={fragment.id} className={getCellClass(fragment.styleOption)}>
                      <Headline content={fragment.content} animation={fragment.animation} />
                    </div>
                  )
                case 'ContentfulFragmentRichText':
                  return (
                    <div key={fragment.id} className={getCellClass(fragment.styleOption)}>
                      <RichText content={fragment.content} />
                    </div>
                  )
                case 'ContentfulFragmentMedia':
                  return (
                    <div
                      key={fragment.id}
                      className={getCellClass(fragment.styleOption)}
                    >
                      <Media media={fragment.media} mediaResponsive={fragment.mediaResponsive}/>
                    </div>
                  )
                case 'ContentfulFragmentHtml':
                  return (
                    <HTMLWrapper key={fragment.id} code={fragment}/>
                  )
                default:
                  return (
                    <div key={fragment.id} className={getCellClass()}>
                      Module not defined yet...
                    </div>
                  )
              }
            })}

          </div>
        </div>

        { useArrow &&
          <a className="go-next bounce" href={secSection} onMouseEnter={getJumpUrl} onClick={handleJump} aria-label="Next Section">
            <span className="arrow-down"></span>
          </a>
        }

        { backgroundImage &&
          <div className={`section__bg ${getBackgroundClass(section)}`}>

            { (backgroundImage.gatsbyImageData && !isBackgroundVideo) &&
              <GatsbyImage
                image={backgroundImage.gatsbyImageData}
                alt={backgroundImage.title}
                objectFit={getBackgroundClass(section) === 'center' ? 'cover' : 'contain'}
                loading={loadingClass}
                layout="fullWidth"
                sizes={'(max-width: 480px) 480px, (max-width: 960px) 960px, (max-width: 1200px) 1200px, (max-width: 1920px) 1920px'}
              />
            }

            { (!backgroundImage.gatsbyImageData && !isBackgroundVideo) &&
              <img
                src={backgroundImage.file.url}
                alt={backgroundImage.title}
                loading={loadingClass}
              />
            }

            { isBackgroundVideo &&
              <video className="background-video hide-for-small-only" muted loop autoPlay>
                <source src={backgroundImage.file.url} type="video/mp4" />
                <track/>
              </video>
              }
          </div>
        }
      </div>
    </section>
  )
}

export default Section
